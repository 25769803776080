<template>
  <section>
    <ClFormRow v-for="c of fields" :key="c.id" :label="c.name" :toolTipText="c.toolTipText">
      <template #content>
        <CInput
          v-if="c.fieldType === 'Text' || c.fieldType === 'Number'"
          :type="c.fieldType === 'Text' ? 'text' : 'number'"
          :name="c.name"
          v-model="c.val"
          :placeholder="c.name"
        />
        <CIArrayField
          type="text"
          v-model="c.val"
          v-if="c.fieldType === 'TextArray'"
        />
        <CIArrayField
          type="number"
          v-model="c.val"
          v-if="c.fieldType === 'NumberArray'"
        />
        <CITextArea
          v-if="c.fieldType === 'Textarea'"
          v-model="c.val"
          :value="c.val"
          :isColumn="true"
          :isToolbar="isToolbar"
        />
      </template>
    </ClFormRow>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {cloneDeep} from "lodash";

export default {
  name: 'CICustomFields',
  props: {
    type: String,
    val: Object,
    sortBy: null,
    isToolbar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('lookups', ['customFieldsList']),
  },
  data() {
    return {
      fields: []
    };
  },
  watch: {
    fields: {
      deep: true,
      handler() {
        const obj = {};
        this.fields.forEach((field) => {
          obj[field.key] = field.val;
        });
        this.$emit('input', Object.keys(obj).length ? obj : null);
      },
    },
  },
  created() {
    const queryType = this.type
      .replace('Create', '')
      .replace('Update', '')
      .replace('Request', '')
      .replace('Simple', '')
      .replace('Entity', '');

    if (queryType) {
      this.handleGetCustomFieldsByAppliesTo({
        queryValue: queryType,
        skip: 0,
        limit: 1000,
      }).then(() => {
        this.processDataBinding();
      });
    }
  },
  methods: {
    ...mapActions('lookups', ['handleGetCustomFieldsByAppliesTo']),
    processDataBinding() {
      const customFieldsList = cloneDeep(this.customFieldsList);
      if (this.sortBy) {
        customFieldsList.sort((a, b) => a.name.localeCompare(b.name));
      }

      this.fields = [];
      customFieldsList.forEach((customField) => {
        const obj = { ...customField };

        obj.toolTipText = obj.description ? obj.description : 'This is a custom field';

        if (obj.key === 'terms-and-conditions') obj.fieldType = 'Textarea';

        // Make the new attribute as two-way compatible
        Vue.set(obj, 'val', this.val ? this.val[customField.key] : null);

        this.fields.push(obj);
      });
    },
  },
};
</script>
